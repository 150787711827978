:root {
	--ion-color-primary: #ffc608;
	--ion-color-primary-rgb: 255, 198, 8;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #e0ae07;
	--ion-color-primary-tint: #ffcc21;

	--ion-color-secondary: #07d7cc;
	--ion-color-secondary-rgb: 7, 215, 204;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #06bdb4;
	--ion-color-secondary-tint: #20dbd1;

	--ion-color-tertiary: #522f24;
	--ion-color-tertiary-rgb: 82, 47, 36;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #482920;
	--ion-color-tertiary-tint: #63443a;

	--ion-color-success: #4da167;
	--ion-color-success-rgb: 77, 161, 103;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #448e5b;
	--ion-color-success-tint: #5faa76;

	--ion-color-warning: #ffc608;
	--ion-color-warning-rgb: 255, 198, 8;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ae07;
	--ion-color-warning-tint: #ffcc21;

	--ion-color-danger: #c0392b;
	--ion-color-danger-rgb: 192, 57, 43;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #a93226;
	--ion-color-danger-tint: #c64d40;

	--ion-color-light: #222428;
	--ion-color-light-rgb: 34, 36, 40;
	--ion-color-light-contrast: #ffffff;
	--ion-color-light-contrast-rgb: 255, 255, 255;
	--ion-color-light-shade: #1e2023;
	--ion-color-light-tint: #383a3e;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-dark: #f4f5f8;
	--ion-color-dark-rgb: 244, 245, 248;
	--ion-color-dark-contrast: #060b0e;
	--ion-color-dark-contrast-rgb: 6, 11, 14;
	--ion-color-dark-shade: #d7d8da;
	--ion-color-dark-tint: #f5f6f9;

	--ion-color-divide: #39474f;
	--ion-color-divide-rgb: 57, 71, 79;
	--ion-color-divide-contrast: #ffffff;
	--ion-color-divide-contrast-rgb: 255, 255, 255;
	--ion-color-divide-shade: #323e46;
	--ion-color-divide-tint: #4d5961;

	--item-card-background: #162b36;
	--flagger-red: #007bff;
	--flagger-white: #eef1f3;

	ion-card {
		--background: var(--item-card-background);
	}
	.cardText {
		color: #dddddd;
	}
	ion-segment-button {
		--color: #dddddd;
	}

	.buttonCardShadow {
		box-shadow: 0px 4px 10px 2px var(--ion-color-light-shade);
	}

	.homeHighlight {
		//box-shadow: 0px 4px 10px 2px var(--ion-color-light-shade);
		border: 3px solid #eef1f4;
	}

	.dropzone {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 250px;
		border: 3px dashed #dddddd;
		border-radius: 5px;
		background: rgba(255, 255, 255, 0);
		margin: 10px 0;

		&.hovering {
			border: 3px solid #4c8c4a;
			background-color: #4c8c4a5e;
		}
	}

	.sectionTitle {
		background-color: var(--ion-toolbar-background);
		--ion-background-color: var(--ion-toolbar-background);
	}
}

body {
	font-family: "CreatoDisplay-Regular";

	--ion-color-primary: #ffc608;
	--ion-color-primary-rgb: 255, 198, 8;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #e0ae07;
	--ion-color-primary-tint: #ffcc21;

	--ion-color-secondary: #07d7cc;
	--ion-color-secondary-rgb: 7, 215, 204;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #06bdb4;
	--ion-color-secondary-tint: #20dbd1;

	--ion-color-tertiary: #522f24;
	--ion-color-tertiary-rgb: 82, 47, 36;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #482920;
	--ion-color-tertiary-tint: #63443a;

	--ion-color-success: #4da167;
	--ion-color-success-rgb: 77, 161, 103;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #448e5b;
	--ion-color-success-tint: #5faa76;

	--ion-color-warning: #ffc608;
	--ion-color-warning-rgb: 255, 198, 8;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ae07;
	--ion-color-warning-tint: #ffcc21;

	--ion-color-danger: #c0392b;
	--ion-color-danger-rgb: 192, 57, 43;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #a93226;
	--ion-color-danger-tint: #c64d40;

	--ion-color-light: #222428;
	--ion-color-light-rgb: 34, 36, 40;
	--ion-color-light-contrast: #ffffff;
	--ion-color-light-contrast-rgb: 255, 255, 255;
	--ion-color-light-shade: #1e2023;
	--ion-color-light-tint: #383a3e;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-dark: #f4f5f8;
	--ion-color-dark-rgb: 244, 245, 248;
	--ion-color-dark-contrast: #060b0e;
	--ion-color-dark-contrast-rgb: 6, 11, 14;
	--ion-color-dark-shade: #d7d8da;
	--ion-color-dark-tint: #f5f6f9;

	--ion-color-divide: #39474f;
	--ion-color-divide-rgb: 57, 71, 79;
	--ion-color-divide-contrast: #ffffff;
	--ion-color-divide-contrast-rgb: 255, 255, 255;
	--ion-color-divide-shade: #323e46;
	--ion-color-divide-tint: #4d5961;

	--item-card-background: var(--ion-color-step-100);

	--ion-background-color: #0e6b7a;
	--ion-background-color-rgb: 14, 107, 122;

	--ion-text-color: #eef1f4;
	--ion-text-color-rgb: 238, 241, 244;

	--ion-color-step-50: #31383f;
	--ion-color-step-100: #39474f;
	--ion-color-step-150: #435057;
	--ion-color-step-200: #4d5960;
	--ion-color-step-250: #566269;
	--ion-color-step-300: #606b72;
	--ion-color-step-350: #69747a;
	--ion-color-step-400: #737d83;
	--ion-color-step-450: #7c868c;
	--ion-color-step-500: #868f94;
	--ion-color-step-550: #8f989d;
	--ion-color-step-600: #99a0a6;
	--ion-color-step-650: #a2a9ae;
	--ion-color-step-700: #acb2b7;
	--ion-color-step-750: #b5bbc0;
	--ion-color-step-800: #bfc4c9;
	--ion-color-step-850: #c8cdd1;
	--ion-color-step-900: #d1d6da;
	--ion-color-step-950: #dbdfe3;

	--ion-toolbar-background: var(--ion-color-step-200);
	//--ion-item-background: #18113a00;

	ion-card {
		--background: var(--item-card-background);
	}
	.cardText {
		color: #dddddd;
	}
	ion-segment-button {
		--color: #dddddd;
	}

	ion-button {
		ion-select::part(icon) {
			color: var(--ion-color-primary-contrast);
		}
	}

	.buttonCardShadow {
		box-shadow: 0px 4px 10px 2px var(--ion-color-light-shade);
	}

	.dropzone {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 250px;
		border: 3px dashed #dddddd;
		border-radius: 5px;
		background: rgba(255, 255, 255, 0);
		margin: 10px 0;

		&.hovering {
			border: 3px solid #4c8c4a;
			background-color: #4c8c4a5e;
		}
	}

	.sectionTitle {
		background-color: var(--ion-toolbar-background);
		--ion-background-color: var(--ion-toolbar-background);
	}
}

.menuText {
	text-align: center;
	justify-content: center;
	text-transform: uppercase;
	font-family: "CreatoDisplay-Regular";
	font-weight: bold;
}

.center {
	text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: "CreatoDisplay-Regular";
	color: var(--flagger-white);
}

.fullscreen-modal-css {
	--height: 100%;
	--width: 100%;
	color: var(--ion-color-primary) !important;
}

.dividerFormatting {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
	border-radius: 10px;
}

.filters {
	display: flex;
	flex-wrap: wrap;
}

.filterIcon {
	padding-right: 8px;
	color: var(--ion-text-color);
}

.filterIconTertiary {
	padding-right: 8px;
	color: var(--ion-color-tertiary-contrast);
}

.ion-color-divide {
	--ion-color-base: var(--ion-color-divide);
	--ion-color-base-rgb: var(--ion-color-divide-rgb);
	--ion-color-contrast: var(--ion-color-divide-contrast);
	--ion-color-contrast-rgb: var(--ion-color-divide-contrast-rgb);
	--ion-color-shade: var(--ion-color-divide-shade);
	--ion-color-tint: var(--ion-color-divide-tint);
}

ion-item {
	--border-color: var(--ion-color-step-450);
	// background-color: #0c525d;
	// --ion-background-color: #0c525d;
}

ion-note {
	color: var(--ion-color-step-450);
}
